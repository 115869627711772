import React from 'react';

function Solution() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-lg-5">
                <h4 className="mb-50">02 . The Solution</h4>
              </div>
              <div className="col-lg-7">
                <div className="text">
                  <p className="fz-18">
                  To address the challenges in Kensick’s social media marketing, we will focus on establishing a strong and cohesive brand identity that resonates with our target audience. By creating a diverse content calendar, we will share engaging posts that highlight products, showcase customer stories, and offer behind-the-scenes insights to foster community engagement. Targeted advertising will help expand our reach, while regular performance analysis will allow us to adapt strategies based on audience interaction. This approach will enhance Kensick's online presence and drive meaningful connections with our audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solution;
