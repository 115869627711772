import React from 'react';

function Privacy() {
  return (
    <section className="services-details section-padding">
      <div className="container">
        <div className="sec-head text-left mb-80">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div>
                <h6 className="sub-title main-color mb-15 text-center">
                  Privacy Policy
                </h6>
                <h4>
                  <p>
                    Aashway is committed to protecting and
                    respecting your privacy. This Privacy Policy outlines how we collect,
                    use, and protect your personal information when you use our website
                    and services.
                  </p>
                  <p>
                    By using our website and services, you agree to the terms of this
                    Privacy Policy.
                  </p>

                  <p><strong>1. Information We Collect</strong></p>
                  <p>We collect the following types of information:</p>

                  <p><strong>a. Personal Information</strong></p>
                  <p>
                    When you interact with our website or services, we may ask you to
                    provide personal information that can be used to contact or identify
                    you. This information may include:
                  </p>
                  <ul>
                    <li><p>Name</p></li>
                    <li><p>Email address</p></li>
                    <li><p>Phone number</p></li>
                    <li><p>Job title</p></li>
                    <li><p>Company name</p></li>
                  </ul>

                  <p><strong>b. Usage Data</strong></p>
                  <p>
                    We collect information that your browser sends whenever you visit our
                    website
                  </p>
                  <p><strong>c. Cookies and Tracking Technologies</strong></p>
                  <p>
                    We use cookies and similar tracking technologies to track your activity
                    on our website and hold certain information. Cookies are files with a
                    small amount of data, which may include an anonymous unique identifier.
                  </p>

                  <p><strong>2. How We Use Your Information</strong></p>
                  <p>
                    Aashway uses the information we collect for various purposes, including:
                  </p>
                  <ul>
                    <li><p>To provide and maintain our services</p></li>
                    <li><p>To notify you about changes to our services</p></li>
                    <li><p>To provide customer support</p></li>
                    <li><p>To monitor the usage of our services</p></li>
                    <li><p>To detect, prevent, and address technical issues</p></li>
                    <li><p>To improve our website, products, and services</p></li>
                    <li><p>
                      To send marketing and promotional materials (you can opt-out at any
                      time)
                      </p>
                    </li>
                  </ul>

                  <p><strong>3. Sharing of Your Information</strong></p>
                  <p>
                    We do not sell, trade, or otherwise transfer your personal information
                    to outside parties, except in the following situations:
                  </p>
                  <ul>
                    <li>
                      <p>
                      <strong>Service Providers:</strong> We may share your information with
                      trusted third-party providers who assist us in delivering our
                      services.
                      </p>
                    </li>
                    <li>
                      <p>
                      <strong>Compliance with Laws:</strong> We may disclose your personal
                      information where required by law or to protect our legal rights.
                      </p>
                    </li>
                    <li>
                      <p>
                      <strong>Business Transactions:</strong> In case of a merger,
                      acquisition, or sale of assets, your personal information may be
                      transferred to the new entity.
                      </p>
                    </li>
                  </ul>

                  <p><strong>4. Data Security</strong></p>
                  <p>
                    We take reasonable precautions to protect your personal information.
                    However, no method of transmission over the internet or electronic
                    storage is 100% secure, and we cannot guarantee its absolute security.
                  </p>

                  <p><strong>5. Your Rights</strong></p>
                  <p>
                    Depending on your location, you may have the following rights concerning
                    your personal data:
                  </p>
                  <ul>
                    <li><p><strong>Access and Correction:</strong> You have the right to access and correct your personal data.</p></li>
                    <li><p><strong>Data Deletion:</strong> You can request the deletion of your personal information, subject to certain conditions.</p></li>
                    <li><p><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications from us by following the unsubscribe link or instructions provided in the email.</p></li>
                  </ul>

                  <p><strong>6. Third-Party Links</strong></p>
                  <p>
                    Our website may contain links to third-party websites or services that
                    are not owned or controlled by Aashway. We are not responsible for the
                    privacy practices of these third-party websites and encourage you to
                    review their privacy policies.
                  </p>

                  <p><strong>7. Children’s Privacy</strong></p>
                  <p>
                    Our services are not intended for use by individuals under the age of
                    13, and we do not knowingly collect personal data from children. If we
                    become aware that we have inadvertently collected personal information
                    from a child under the age of 13, we will take steps to delete it.
                  </p>

                  <p><strong>8. Changes to This Privacy Policy</strong></p>
                  <p>
                    We may update our Privacy Policy from time to time. Any changes will be
                    posted on this page, and we will revise the "Effective Date" above. We
                    encourage you to review this page periodically for any changes.
                  </p>

                  <p><strong>9. Contact Us</strong></p>
                  <p>
                    If you have any questions or concerns about this Privacy Policy or the
                    practices of our website, please contact us:
                  </p>
                  <ul>
                    <li><p>Email: privacy@aashway.com</p></li>
                    <li><p>Phone: +918652416407,+919067474203</p></li>
                    <li><p>Address: Address: Thane,Maharashtra,India - 400603</p></li>
                  </ul>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Privacy;
