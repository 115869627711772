import React from 'react';

function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white">
        <div className="container">
          <a className="logo icon-img-100" href="/">
            <img src="/light/assets/imgs/logo.png" alt="logo" />
          </a>

          <div className="topnav">
            <div className="menu-icon cursor-pointer">
              <span className="icon ti-align-right"></span>
            </div>
          </div>
        </div>
      </nav>

      <div className="hamenu">
        <div className="logo icon-img-100">
          <img src="/light/assets/imgs/logo-light.png" alt="" />
        </div>
        <div className="close-menu cursor-pointer ti-close"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="menu-text">
                <div className="text">
                  <h2>Menu</h2>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="menu-links">
                <ul className="main-menu rest">
                  <li>
                    <div className="o-hidden">
                      <a href="/" className="link">
                        <span className="fill-text" data-text="Home">
                          Home
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <div className="link cursor-pointer dmenu">
                        <span className="fill-text" data-text="Services">
                          Services
                        </span>{' '}
                        <i></i>
                      </div>
                    </div>
                    <div className="sub-menu no-bord">
                      <ul>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/web-development" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="Web Development">
                                Web Development
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/social-media-marketing" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="Social Media Marketing">
                                Social Media Marketing
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/graphic-designing" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="Graphic Designing">
                                Graphic Designing
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                        <li>
                          <div className="o-hidden">
                            <a href="/services/ui-ux-designing" className="link cursor-pointer sub-dmenu" style={{padding: "20px 30px", paddingLeft:"50px"}}>
                              <span className="fill-text" data-text="UI - UX Designing">
                                UI - UX Designing
                              </span>{' '}
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <a href="/about-us" className="link">
                        <span className="fill-text" data-text="About Us">
                          About Us
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <a href="https://forms.gle/RAZvwT76nk3AixL47" className="link">
                        <span className="fill-text" data-text="Internship">
                          Internship
                        </span>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="o-hidden">
                      <a href="/contact" className="link">
                        <span className="fill-text" data-text="Contact Us">
                          Contact Us
                        </span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="cont-info">
                <div className="item mb-50">
                  <h6 className="sub-title mb-15 opacity-7">Address</h6>
                  <h5>
                  Thane, <br /> Maharashtra, India - 400603
                  </h5>
                </div>
                <div className="item mb-50">
                  <h6 className="sub-title mb-15 opacity-7">Social Media</h6>
                  <ul className="rest social-text">
                    <li className="mb-10">
                      <a href="https://www.linkedin.com/company/aashway/" className="hover-this">
                        <span className="hover-anim">LinkedIn</span>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/beaashway/" className="hover-this">
                        <span className="hover-anim">Instagram</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="item mb-40">
                  <h6 className="sub-title mb-15 opacity-7">Contact Us</h6>
                  <h5>
                    <a href="mailto:aashway.com@gmail.com">aashway.com@gmail.com</a>
                  </h5>
                  <h5 className="underline mt-10">
                    <a href="tel:+91 86524 16407">+91 86524 16407</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
