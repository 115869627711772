import React from 'react';

function Challenge() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div>
              <h4 className="mb-30">01 . The Challenge</h4>
              <div className="text">
                <h5 className="mb-50 fw-400 line-height-40">
                  EditByAbhay faces several challenges in the competitive landscape of video editing services. One major challenge is establishing a unique brand identity that distinguishes it from numerous other editing platforms and freelancers in the market. 
                </h5>
                <div className="row justify-content-end">
                  <div className="col-lg-9">
                    <p className="fz-18">
                    With many variations of editing styles and techniques available, ensuring consistency in quality and style across different projects is essential for building trust and reliability with clients. Additionally, managing client expectations can be tricky, as each client may have varying levels of understanding regarding the editing process and the time required to achieve the desired results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
