import React from 'react';

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="modern-footer pt-80">
      <div className="footer-container">
        <div className="container ontop">
          <div className="row pb-30 bord-thin-bottom align-items-center">
            <div className="col-lg-5">
              <div className="logo icon-img-120">
                <img src="/light/assets/imgs/white-logo.png" alt="" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="social-media d-flex justify-content-end">
                <ul className="rest d-flex align-items-center fz-14">
                  <li className="cursor-pointer ml-50">
                    <a href="https://www.linkedin.com/company/aashway/" className="hover-anim">
                      <i className="fab fa-linkedin-in mr-10"></i> LinkedIn
                    </a>
                  </li>
                  <li className="cursor-pointer ml-50">
                    <a href="https://www.instagram.com/beaashway/" className="hover-anim">
                      <i className="fab fa-instagram mr-10"></i> Instagram
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row pt-80">
            <div className="col-lg-7">
              <div className="call-action">
                <h2 className="d-slideup wow fz-60 fw-600">
                  <span className="sideup-text">
                    <span className="up-text">Have a project in mind?</span>
                  </span>
                  <span className="sideup-text">
                    <span className="up-text underline">
                      <a href="/contact" className="main-color">
                        Let’s get to work.
                      </a>
                    </span>
                  </span>
                </h2>
                <div className="info mt-80 d-flex align-items-center">
                  <div>
                    <a
                      href="mailto:aashway.com@gmail.com"
                      className="butn butn-md butn-bord-thin radius-30"
                    >
                      <span className="text">aashway.com@gmail.com</span>
                    </a>
                  </div>
                  <div>
                    <a
                      href="tel:+91 86524 16407"
                      className="butn butn-md butn-bord-thin radius-30 ml-30"
                    >
                      <span className="text">+91 86524 16407</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 bord-left">
              <div className="column">
                <h6 className="sub-title mb-30">Useful Links</h6>
                <div className="row">
                  <div className="col-6">
                    <ul className="rest fz-14">
                      <li className="mb-15">
                        <a href="/about-us">About</a>
                      </li>
                      <li className="mb-15">
                        <a href="/services">Services</a>
                      </li>
                      <li>
                        <a href="/contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="rest fz-14">
                      {/* <li className="mb-15">
                        <a href="/light/page-FAQS">FAQS</a>
                      </li> */}
                      <li className="mb-15">
                        <a href="/terms-conditions">Term & Conditions</a>
                      </li>
                      <li className="mb-15">
                        <a href="/privacy-policy">Privacy policy</a>
                      </li>
                      {/* <li>
                        <a href="/light/page-about2">Help</a>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="subscribe-minimal mt-50">
                <form action="#">
                  <div className="form-group rest">
                    <input type="email" placeholder="Type Your Email" />
                    <button type="submit">
                      <i className="ti-arrow-top-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="text-center pt-30 pb-30 sub-bg mt-80">
            <p className="fz-14">
              © {year} Aashway | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
