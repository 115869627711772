import React from 'react';

function Solution() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-lg-5">
                <h4 className="mb-50">02 . The Solution</h4>
              </div>
              <div className="col-lg-7">
                <div className="text">
                  <p className="fz-18">
                  To tackle the challenges at EditByAbhay, we will establish a strong brand identity by showcasing a diverse and professional portfolio that highlights our editing expertise. Clear communication with clients will be prioritized to manage expectations and explain the editing process effectively. We will stay updated on industry trends and continuously enhance our skills and tools to provide modern editing techniques. Additionally, leveraging social media and targeted marketing strategies will help increase our visibility and attract potential clients, positioning EditByAbhay as a trusted choice for video editing services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solution;
