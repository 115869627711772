import React from 'react';

function Process() {
  return (
    <section className="process section-padding">
      <div className="container">
        <div className="sec-head mb-80">
          <h6 className="sub-title main-color mb-25">Our Process</h6>
          <div className="bord pt-25 bord-thin-top d-flex align-items-center">
            <h2 className="fw-600">
              Business <span className="fw-200">Development</span>
            </h2>
          </div>
        </div>
        <div className="row md-marg">
          <div className="col-lg-3 col-md-6 item md-mb50">
            <div className="icon-img-60 mb-30">
              <img src="/light/assets/imgs/serv-icons/3.png" alt="" />
            </div>
            <h5 className="mb-15">Research</h5>
            <p>
            Gathering and analyzing data to understand market trends, customer needs, and competitive landscape. This phase builds the foundation for informed decisions and strategic planning.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 item md-mb50">
            <div className="icon-img-60 mb-30">
              <img src="/light/assets/imgs/serv-icons/4.png" alt="" />
            </div>
            <h5 className="mb-15">Concept</h5>
            <p>
            Developing creative ideas and solutions based on the research findings. This step involves brainstorming, ideation, and refining concepts that align with business objectives and customer needs.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 item sm-mb50">
            <div className="icon-img-60 mb-30">
              <img src="/light/assets/imgs/serv-icons/5.png" alt="" />
            </div>
            <h5 className="mb-15">Implement</h5>
            <p>
            Turning concepts into reality through detailed execution. This phase focuses on the practical application of ideas, involving design, development, and testing to ensure the solution is functional and effective.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 item">
            <div className="icon-img-60 mb-30">
              <img src="/light/assets/imgs/serv-icons/6.png" alt="" />
            </div>
            <h5 className="mb-15">Handover</h5>
            <p>
            Delivering the final product or solution to the client or internal teams. This includes training, documentation, and ensuring a smooth transition for future maintenance and scaling.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
