import React from 'react';

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer
      className="clean-footer crev bg-img"
      data-background="/light/asstes/imgs/header/bg1.jpg"
      data-overlay-dark="10"
    >
      <div className="container pb-40 section-padding ontop">
        <div className="cal-action pb-50 mb-80 bord-thin-bottom">
          <h2 className="fz-120 line-height-1 d-rotate wow">
            <span className="rotate-text">
              <a href="/contact">
                Let’s Contact{' '}
                <span className="ml-30 fz-70 ti-arrow-top-right"></span>
              </a>
            </span>
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="logo icon-img-100">
              <img src="/light/asstes/imgs/logo-light.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="column">
              <div className="mb-40">
                <h6 className="sub-title mb-30">Address</h6>
                <h5 className="opacity-8">
                  Thane, <br /> Maharashtra, India - 400603
                </h5>
              </div>
              <a href="tel:+91 86524 16407">
                <span className="fz-22 main-color">+91 86524 16407</span>
              </a>
              <span className="mx-2">|</span>
              <a href="tel:+91 90674 74203">
                <span className="fz-22 main-color">+91 90674 74203</span>
              </a>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-1">
            <div className="column">
              <h6 className="sub-title mb-30">Useful Links</h6>
              <ul className="rest fz-14">
                <li className="mb-15">
                  <a href="/about-us">About</a>
                </li>
                <li className="mb-15">
                  <a href="/services">Services</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="column">
              <h6 className="sub-title mb-30">Support</h6>
              <ul className="rest fz-14">
                <li className="mb-15">
                  <a href="/terms-conditions">Term & Conditions</a>
                </li>
                <li className="mb-15">
                  <a href="/privacy-policy">Privacy policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center pt-30 pb-30 mt-80 bord-thin-top">
          <div>
            <ul className="rest d-flex align-items-center">
              <li className="cursor-pointer ml-30">
                <a href="https://www.linkedin.com/company/aashway/" className="hover-anim">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li className="cursor-pointer ml-30">
                <a href="https://www.instagram.com/beaashway/" className="hover-anim">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="ml-auto">
            <p className="fz-14">
              © {year} Aashway | All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
