import React from 'react';

function Story() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="img">
              <img src="/light/assets/imgs/works/projects/2/Aroma-7.jpeg" alt="" />
            </div>
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 sm-mb30">
                  <h6>The Story</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                    Aroma Snacks Cafe was born out of a passion for bringing people together over delicious, handcrafted snacks and beverages. As a cozy haven in the heart of the community, we offer a space where everyone—from busy professionals to casual snack enthusiasts—can find a moment of relaxation and indulgence. Our journey began with a simple idea: to create a place where the aromas of freshly brewed coffee and warm, baked goods invite you to slow down and savor the moment.
At                  Aroma Snacks Cafe, we believe that great food should be an experience, not just a meal. That’s why we focus on crafting unique, high-quality snacks that not only satisfy your cravings but also delight your senses. From the carefully selected ingredients to the artful presentation, every detail is designed to reflect our commitment to excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="img">
              <img src="/light/assets/imgs/works/projects/2/Aroma-6.png" alt="" />
            </div>
            <div className="cont section-padding">
              <div className="row">
                <div className="col-md-3 offset-lg-2 sm-mb30">
                  <h6>Approach</h6>
                </div>
                <div className="col-lg-6 col-md-9">
                  <div className="text">
                    <p>
                    At Aroma Snacks Cafe, we prioritize creating delightful experiences through high-quality, handcrafted snacks that cater to our customers’ tastes. We focus on building a strong online presence that reflects the warm and inviting atmosphere of our cafe, using engaging social media content and targeted marketing strategies to connect with our community. Our user-friendly website allows customers to easily explore our menu, place orders, and stay updated with our latest offerings. By blending exceptional product quality with seamless digital engagement, we strive to make every visit—online or in-person—a memorable one.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="img">
              <img src="/light/assets/imgs/works/projects/2/Aroma-8.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Story;
