import React from 'react';

function Solution() {
  return (
    <section className="section-padding bord-thin-top">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-7">
            <div className="text">
              <h2 className="mb-50">The Solution</h2>
              <h5 className="mb-30 fw-400">
              To address the challenges of the "Celestial Celebration" project, we propose a comprehensive and innovative approach that blends aesthetics with functionality. By collaborating closely with designers and developers, we’ll create a visually stunning and cohesive celestial theme that resonates across all platforms.
              </h5>
              <p className="fz-18">
              The design will embrace minimalism and elegance, ensuring that each element is not only beautiful but also functional. Utilizing a modern, fully adaptive template, we will craft a seamless user experience that works flawlessly on any device. The template will be designed with clean, professional code, enabling easy integration of multimedia elements such as videos, images, and sliders. This allows for a dynamic presentation of your content while maintaining the celestial theme. By focusing on precision and attention to detail, we will ensure that the final product is both unique and engaging, delivering an exceptional experience that captures the essence of the "Celestial Celebration."
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Solution;
