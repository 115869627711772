import React from 'react';

function Challenge() {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="info mb-80 pb-20 bord-thin-bottom">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Category :</span>
                <h6>Social Media Marketing</h6>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Client :</span>
                <h6>Kensick</h6>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="item mb-30">
                <span className="opacity-8 mb-5">Start Date :</span>
                <h6>13 November 2023</h6>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="item">
                <span className="opacity-8 mb-5">Designer :</span>
                <h6>Team Rahul</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="row">
              <div className="col-lg-5">
                <h4 className="mb-50">01 . The Challenge</h4>
              </div>
              <div className="col-lg-7">
                <div className="text">
                  <h5 className="mb-30 fw-400 line-height-40">
                  Kensick faces several challenges in its social media marketing efforts, primarily stemming from the need to establish a distinctive brand identity in a competitive landscape. With numerous brands vying for attention, crafting a unique voice that resonates with the target audience while conveying the essence of Kensick's offerings is critical.
                  </h5>
                  <p className="fz-18">
                  Balancing promotional content with valuable, entertaining posts is vital to keep the audience engaged without overwhelming them with sales pitches. Lastly, measuring the effectiveness of campaigns and understanding audience behavior through analytics is crucial for refining future strategies and achieving sustainable growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
