import React from 'react';

function Terms() {
  return (
    <section className="services-details section-padding">
      <div className="container">
        <div className="sec-head text-left mb-80">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div>
                <h6 className="sub-title main-color mb-15 text-center">
                  Terms of Services
                </h6>
                <h4>
                    <p>Welcome to Aashway!</p>
                    <p>These terms and conditions outline the rules and regulations for the use of Aashway's Website, located at <a href="http://www.aashway.com">www.aashway.com</a>.</p>
                    <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Aashway's website if you do not agree to take all of the terms and conditions stated on this page.</p>

                    <p><strong>1. Definitions and Key Terms</strong></p>
                    <p>For these Terms & Conditions:</p>
                    <ul>
                        <li><p> - “Client,” “You,” and “Your” refer to you, the person accessing this website and complying with the terms and conditions of the Company.</p></li>
                        <li><p> - “Company,” “We,” and “Our” refer to Aashway.</p></li>
                        <li><p> - “Service” refers to the services offered by Aashway, including but not limited to web development, graphic designing, UI/UX design, social media marketing, and more.</p></li>
                    </ul>
                    
                    <p><strong>2. Cookies</strong></p>
                    <p>We employ the use of cookies. By accessing Aashway, you agree to use cookies in agreement with Aashway's Privacy Policy.</p>

                    <p><strong>3. License</strong></p>
                    <p>Unless otherwise stated, Aashway and/or its licensors own the intellectual property rights for all material on Aashway. All intellectual property rights are reserved. You may access this from Aashway for your personal use subjected to restrictions set in these terms and conditions.</p>
                    <p>You must not:</p>
                    <ul>
                        <li><p>Republish material from Aashway</p></li>
                        <li><p>Sell, rent, or sub-license material from Aashway</p></li>
                        <li><p>Reproduce, duplicate, or copy material from Aashway</p></li>
                        <li><p>Redistribute content from Aashway</p></li>
                    </ul>

                    <p><strong>4. User-Generated Content</strong></p>
                    <p>Certain parts of this website may offer users the opportunity to post comments and opinions. Aashway does not filter, edit, or review comments before they appear on the website. Comments reflect the views of the person who posts them. To the extent permitted by applicable laws, Aashway shall not be liable for the Comments or for any liability, damages, or expenses caused by the use of and/or appearance of the Comments on this website.</p>

                    <p><strong>5. Disclaimer of Liability</strong></p>
                    <p>Aashway makes no representations, warranties, or assurances regarding the accuracy or completeness of the content of this website. Aashway will not be responsible or liable for any errors or omissions in the content or for any losses, damages, or liabilities arising from the use or misuse of the information provided on this website.</p>

                    <p><strong>6. Service Availability</strong></p>
                    <p>While Aashway strives to ensure that the website is available at all times, we do not guarantee that the website will be available at all times or that access to the website will be uninterrupted. Aashway reserves the right to modify, suspend, or terminate the website or any part of it without notice at any time.</p>

                    <p><strong>7. Third-Party Links</strong></p>
                    <p>Our website may contain links to external websites that are not operated by us. We are not responsible for the content or privacy practices of such websites. We encourage you to review the terms and conditions and privacy policies of any third-party websites you visit.</p>

                    <p><strong>8. Limitation of Liability</strong></p>
                    <p>In no event shall Aashway, nor any of its directors, employees, or affiliates, be liable for any indirect, incidental, or consequential damages arising out of or in connection with the use of this website, the services we offer, or the information provided.</p>

                    <p><strong>9. Termination</strong></p>
                    <p>Aashway reserves the right to terminate your access to the website, without any advance notice, for any reason, including violation of these Terms & Conditions.</p>

                    <p><strong>10. Governing Law</strong></p>
                    <p>These Terms & Conditions are governed by and construed in accordance with the laws of the jurisdiction in which Aashway operates. You irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>

                    <p><strong>11. Amendments</strong></p>
                    <p>Aashway reserves the right to modify or replace these terms and conditions at any time. By continuing to access or use our website after any revisions become effective, you agree to be bound by the updated terms.</p>

                    <p><strong>12. Contact Us</strong></p>
                    <p>If you have any questions or concerns about these Terms & Conditions, please contact us at:</p>
                    <ul>
                        <li><p>Email: aashway.com@gmail.com</p></li>
                        <li><p>Address: Thane,Maharashtra,India - 400603</p></li>
                    </ul>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
