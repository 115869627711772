import React from 'react';

function Challenge() {
  return (
    <section className="section-padding bord-thin-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <h2 className="mb-50">
              The <br /> Challenge
            </h2>
          </div>
          <div className="col-lg-7">
            <div className="text">
              <h5 className="mb-30 fw-400 line-height-40">
              The "Celestial Celebration" project faces the complex task of balancing creative expression with functional design. With numerous variations of celestial-themed elements to choose from, ensuring that each design remains visually cohesive and true to the project's theme is a significant challenge.
              </h5>
              <p className="fz-18">
              Additionally, maintaining the authenticity and believability of the celestial concept is crucial, as any deviations or random design elements could undermine the project's integrity. It's essential to avoid any unintended or embarrassing design flaws that might detract from the overall experience. Furthermore, adapting these intricate designs to be responsive and consistent across multiple platforms adds another layer of complexity. Successfully addressing these challenges requires a careful, detail-oriented approach to ensure that every aspect of the project is executed flawlessly, resulting in a captivating and seamless user experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Challenge;
