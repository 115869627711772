import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LHomeCreativeAgency from './pages/light/home-creative-agency';
import LPageAbout3 from './pages/light/page-about3';
import LPageContact from './pages/light/page-contact';
import LPageFAQS from './pages/light/page-FAQS';
import LPageServices from './pages/light/page-services';
import Privacy from './pages/light/privacy-policy';
import Terms from './pages/light/terms-conditions';
import LPageServicesDetails from './pages/light/page-services-details';
import LPageServicesGraphicDetails from './pages/light/page-services-graphic-details';
import LPageServicesMediaDetails from './pages/light/page-services-media-details';
import LPageServicesUIDetails from './pages/light/page-services-ui-details';
import LProject1 from './pages/light/project1';
import LProject2 from './pages/light/project2';
import LProject3 from './pages/light/project3';
import LProject4 from './pages/light/project4';
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <Router>
      <Analytics />
      <Routes>
        <Route
          path="/"
          element={<LHomeCreativeAgency />}
        />
        <Route path="/about-us" element={<LPageAbout3 />} />
        <Route path="/contact" element={<LPageContact />} />
        <Route path="/FAQS" element={<LPageFAQS />} />
        <Route path="/services" element={<LPageServices />} />
        <Route
          path="/services/web-development"
          element={<LPageServicesDetails />}
        />
        <Route
          path="/services/graphic-designing"
          element={<LPageServicesGraphicDetails />}
        />
        <Route
          path="/services/social-media-marketing"
          element={<LPageServicesMediaDetails />}
        />
        <Route
          path="/services/ui-ux-designing"
          element={<LPageServicesUIDetails />}
        />
        <Route
          path="/terms-conditions"
          element={<Terms />}
        />
        <Route
          path="/privacy-policy"
          element={<Privacy />}
        />
        <Route path="/celestial-project" element={<LProject1 />} />
        <Route path="/aroma-project" element={<LProject2 />} />
        <Route path="/kensick-project" element={<LProject3 />} />
        <Route path="/editbyabhay-project" element={<LProject4 />} />
      </Routes>
    </Router>
  );
}

export default App;
